<template>
  <el-row>
    <el-col class="about-banner" :span="24">
        <div class="text-center">
            <p class="about-title1">超100,000+客户选择了伊登云</p>
            <p class="about-title2">客户的选择是对我们最大的认可</p>
        </div>
    </el-col>
    <el-col :span="24">
        <div class="company-introduce">
            <h1 class="text-center title-bold">公司介绍</h1>
            <div class="introduce-content">
                <p>伊登软件为企业提供全方位的企业IT信息化解决方案，包括文件管理解决方案“伊登云存储”、邮件及大附件解决方案、统一信息沟通、网络安全解决方案等。同时，伊登软件融合全球领先的云技术，助力大中型企业搭建构架安全、高效的私有云，同时为中小型企业提供成熟、稳定、安全的公有云、混合云服务。</p>
                <p>伊登自投入自主产品研发以来，先后开发了“大附件”邮件传输系统、云文档管理系统等一系列企业级应用。云文档管理系统获得深圳市科技创新奖和创新人才奖。同时入围央企采购联盟、华为云、移动云采购目录。</p>
                <p>2020年5月，伊登软件正式登陆香港联交所主板上市，股票代码：H01147。</p>
            </div>
        </div>
    </el-col>
      <el-col :span="24">
            <div>
                <h1 class="text-center title-bold">重点资质</h1>
                <div class="qualification-infos center-infos">
                    <div class="qualification-info">
                        <div class="info-img"><img src="../../img/about/about_zzimg3.png"></div>
                    </div>

                    <div class="qualification-info">
                        <div class="info-img"><img src="../../img/about/about_zzimg2.png"></div>
                    </div>
                    <div class="qualification-info">
                        <div class="info-img"><img src="../../img/about/about_zzimg1.png"></div>
                    </div>
                    <div class="qualification-info">
                        <div class="info-img"><img src="../../img/about/about_zzimg4.png"></div>
                    </div>
                </div>
            </div>
      </el-col>
      <el-col :span="24">
          <div class="cert-content">
              <h1 class="text-center title-bold">认证证书</h1>
              <div class="cert-infos center-infos">
                  <div class="cert-info">
                      <div>
                          <img src="../../img/about/about_zsimg1.png">
                          <div class="text-center cert-text">计算机软件著作证书</div>
                      </div>
                  </div>
                  <div class="cert-info">
                      <div>
                          <img src="../../img/about/about_zsimg2.png">
                          <div class="text-center cert-text">华为技术认证</div>
                      </div>
                  </div>
                  <div class="cert-info">
                      <div>
                          <img src="../../img/about/about_zsimg3.png">
                          <div class="text-center cert-text">鲲鹏技术认证书</div>
                      </div>
                  </div>
                  <div class="cert-info">
                      <div>
                          <img src="../../img/about/about_zsimg4.png">
                          <div class="text-center cert-text">华为技术认证</div>
                      </div>
                  </div>
                  <div class="cert-info">
                      <div>
                          <img src="../../img/about/about_zsimg5.png">
                          <div class="text-center cert-text">计算机软件著作证书</div>
                      </div>
                  </div>
                  <div class="cert-info" style="box-shadow: none">
<!--                      <div>-->
<!--                          <img src="../../img/about/about_zsimg5.png">-->
<!--                          <div class="text-center cert-text">计算机软件著作证书</div>-->
<!--                      </div>-->
                  </div>
              </div>
          </div>
      </el-col>
      <el-col :span="24">
          <div class="contact-content">
              <h1 class="text-center title-bold">联系我们</h1>
              <div class="center-infos contact-infos">
                    <div class="contact-info">
                        <div>
                            <img src="../../img/about/about_contactimg1.png">
                            <p class="contact-title title-default">深圳总部</p>
                            <div class="text-left company-address">
                                深圳市福田区深南大道1006号深圳国际创新中心A栋02层西 <br>
                                电话：0755-88262588<br>
                                传真：0755-88262555
                            </div>
                        </div>
                    </div>
                  <div class="contact-info">
                      <div>
                          <img src="../../img/about/about_contactimg2.png">
                          <p class="contact-title title-default">伊登上海</p>
                          <div class="text-left company-address">
                              徐汇区漕溪北路333号中金B座10层1027<br>
                              电话：021-24113626<br>
                              021-24113628
                          </div>
                      </div>
                  </div>
                  <div class="contact-info">
                      <div>
                          <img src="../../img/about/about_contactimg3.png">
                          <p class="contact-title title-default">伊登东莞</p>
                          <div class="text-left company-address">
                              东莞市南城区第一国际B座808<br>
                              电话：0769-2232 9845<br>
                              传真：0769-2288 2735
                          </div>
                      </div>
                  </div>
                  <div class="contact-info">
                      <div>
                          <img src="../../img/about/about_contactimg4.png">
                          <p class="contact-title title-default">伊登武汉</p>
                          <div class="text-left company-address">
                              武汉市洪山区文化大道555号融科智谷C2栋2单元504<br>
                              电话：027-81296876
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </el-col>
  </el-row>
</template>

<script>
export default {
    name: "about",
    data() {
        return {

        }
    },
    methods: {
        tryApply() {
            this.$router.push({path: '/TryApply'})
        }
    }
}
</script>

<style scoped lang="less">

    * {
        font-size: 16px
    }

    .about-banner p, .introduce-content p {
        margin-top: 0;
        margin-bottom: 2rem;
    }
    .about-banner {
        background: url("../../img/about/about_bg.png") no-repeat  top;
        background-size:  100% 100%;
        min-height: 400px;
        display: flex;
        /*从上往下排列*/
        flex-direction: column;
        /*上下居中*/
        justify-content: center;
        /*左右居中*/
        align-items: center;
    }

    .about-title1 {
        font-size: 40px;
        font-weight: bold;
        color: #FFFFFF;
        opacity: 1;
    }

    .about-title2 {
        font-size: 16px;
        color: #FFFFFF;
        opacity: 1;
    }

    .about-title3 {
        width: 192px;
        height: 40px;
        border-radius: 5px;
        border: 1px solid #FFFFFF;
        line-height: 40px;
        color: #FFFFFF;
        text-align: center;
        cursor: pointer;
    }

    .company-introduce {
        max-width: 1200px;
        width: 100%;
        margin: auto;
        margin-top: 2rem;
    }

    .introduce-content {
        padding: 0rem 2rem;
    }

    .about-qualification {
        max-width: 1200px;
        width: 100%;
        margin: auto;

    }

    .center-infos {
        max-width: 1250px;
        width: 100%;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        flex-grow: 1;
    }

    .qualification-infos {

    }

    .qualification-info {
        flex-grow: 1;
        flex-basis: 20%;
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 1rem;
    }

    .info-img {
        border: 1px solid black;
    }

    .cert-content {
        margin-top: 5rem;
    }

    .cert-infos {

    }

    .cert-info {
        flex-grow: 1;
        flex-basis: 15%;
        padding: 1px 15px 10px 15px;
        margin-left: 10px;
        margin-right: 10px;
        box-shadow: 0px 4px 16px rgba(52,52,78,0.16);
        margin-top: 1rem;
        border-radius: 5px;
    }

    .cert-info img {
        display: block;
        width: 100%;
        height: 100%;
    }

    .cert-text {
        font-size: 16px;
        font-weight: bold;
        line-height: 40px;
        color: #34344E;
    }

    .contact-content {
        margin-top: 5rem;
        margin-bottom: 1rem;
    }

    .contact-infos {
        max-width: 1260px;
    }

    .contact-info {
        flex-grow: 1;
        flex-basis: 20%;
        box-shadow: 0px 4px 16px rgba(52,52,78,0.16);
        padding: 10px 15px 15px 15px;
        margin: 1rem 12px 0px;
    }

    .contact-info p {
        margin: 0px;
    }

    .contact-title {
        font-size: 18px;
        font-weight: bold;
        line-height: 50px;
    }

    .company-address {
        line-height: 25px;
    }

    @media screen and (max-width: 1400px){
        .qualification-infos {
            max-width: 650px;
        }

        .cert-infos {
            max-width: 800px;
        }

        .cert-info {
            flex-basis: 10%;
        }

        .contact-infos {
            max-width: 630px;
        }
    }

    @media screen and (max-width: 800px){
        .about-banner {
            background: url("../../img/about/about_bg_mobile.png") no-repeat  top;
            min-height: 196px;
        }
        .about-title1 {
            font-size: 25px;
        }
        .about-banner p {
            margin-bottom: 1rem;
        }
        .qualification-infos {
            max-width: 100%;
        }

        .info-img {
            border: 0px;
        }

        .info-img img {
            width: 100%;
            height: 100%;
        }

        .qualification-info {
            padding-right: 0px;
            flex-basis: 30%;
        }

        .cert-infos {
            max-width: 100%;
        }

        .cert-info {
            flex-basis: 30%;
            padding: 0px;
            margin: 1rem 5px 0px;
        }
        .cert-text {
            font-size: 12px;
            font-weight: 400;
            line-height: 23px;
        }

        .contact-info {
            flex-basis: 100%;
        }

        .contact-info img {
            height: 100%;
            width: 100%;
        }
    }

    @media screen and (max-width: 678px){
        .contact-infos {
            max-width: 100%;
        }
    }
</style>